import { SortDirection } from '@design/table/table';

export interface PagingWrapper<T> {
  data: T[];
  count: number;
  total: number;
  cursor: {
    previous?: string;
    next?: string;
  };
  paging: {
    offset: number;
    limit: number;
    cursor: string;
  };
}

export type OffsetPagingWrapper<T> = Omit<PagingWrapper<T>, 'cursor'>;

export const EMPTY_PAGING: PagingWrapper<never> = {
  data: [],
  count: 0,
  total: 0,
  cursor: {},
  paging: {
    offset: 0,
    limit: 0,
    cursor: '',
  },
};

export enum PagingOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface PagingOptions<T> {
  limit: number;
  cursor?: string;
  order?: PagingOrder;
  orderBy?: T;
}

export type OffsetPagingOptions<T> = Omit<PagingOptions<T>, 'cursor'> & { offset?: number };

export type SortingOptions<T> = Pick<PagingOptions<T>, 'orderBy' | 'order'>;

export function getPagingOptionsParams<T>(options: PagingOptions<T> | undefined): Record<string, unknown> {
  if (!options) return {};

  return {
    limit: options.limit,
    cursor: options.cursor,
    order: options.order,
    by: options.orderBy,
  };
}

export function getOffsetPagingOptionsParams<T>(options: OffsetPagingOptions<T> | undefined): Record<string, unknown> {
  if (!options) return {};

  return {
    limit: options.limit,
    offset: options.offset,
    order: options.order,
    by: options.orderBy,
  };
}

export function sortDirectionToPagingOrder(sortDirection: SortDirection): PagingOrder | undefined {
  if (sortDirection === null) return undefined;
  return sortDirection === 'asc' ? PagingOrder.Ascending : PagingOrder.Descending;
}
