import * as i0 from '@angular/core';
import { Injectable, NgZone, PLATFORM_ID, Inject, InjectionToken, inject, INJECTOR, Optional, SkipSelf, ErrorHandler, ɵisPromise as _isPromise, computed, makeEnvironmentProviders, ENVIRONMENT_INITIALIZER, NgModule, APP_BOOTSTRAP_LISTENER } from '@angular/core';
import { Observable, config, Subject, of, forkJoin, throwError, EMPTY, from, isObservable, shareReplay as shareReplay$1, map as map$1, catchError as catchError$1, distinctUntilChanged, take as take$1, ReplaySubject } from 'rxjs';
import * as i1 from '@ngxs/store/internals';
import { ɵwrapObserverCalls as _wrapObserverCalls, ɵOrderedSubject as _OrderedSubject, ɵmemoize as _memoize, ɵgetStoreMetadata as _getStoreMetadata, ɵgetSelectorMetadata as _getSelectorMetadata, ɵMETA_KEY as _META_KEY, ɵINITIAL_STATE_TOKEN as _INITIAL_STATE_TOKEN, ɵNgxsAppBootstrappedState as _NgxsAppBootstrappedState, ɵNGXS_STATE_CONTEXT_FACTORY as _NGXS_STATE_CONTEXT_FACTORY, ɵNGXS_STATE_FACTORY as _NGXS_STATE_FACTORY, ɵensureStoreMetadata as _ensureStoreMetadata, ɵMETA_OPTIONS_KEY as _META_OPTIONS_KEY, ɵensureSelectorMetadata as _ensureSelectorMetadata } from '@ngxs/store/internals';
export { StateToken } from '@ngxs/store/internals';
import { isPlatformServer } from '@angular/common';
import { share, map, shareReplay, filter, take, exhaustMap, mergeMap, defaultIfEmpty, catchError, takeUntil, tap, startWith, pairwise } from 'rxjs/operators';
import { NGXS_PLUGINS, getActionTypeFromInstance, setValue, getValue, InitState, UpdateState } from '@ngxs/store/plugins';
export { InitState, NGXS_PLUGINS, UpdateState, actionMatcher, getActionTypeFromInstance, getValue, setValue } from '@ngxs/store/plugins';
import { isStateOperator } from '@ngxs/store/operators';
class NoopNgxsExecutionStrategy {
  enter(func) {
    return func();
  }
  leave(func) {
    return func();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NoopNgxsExecutionStrategy_Factory(t) {
      return new (t || NoopNgxsExecutionStrategy)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NoopNgxsExecutionStrategy,
      factory: NoopNgxsExecutionStrategy.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NoopNgxsExecutionStrategy, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function throwStateNameError(name) {
  throw new Error(`${name} is not a valid state name. It needs to be a valid object property name.`);
}
function throwStateNamePropertyError() {
  throw new Error(`States must register a 'name' property.`);
}
function throwStateUniqueError(current, newName, oldName) {
  throw new Error(`State name '${current}' from ${newName} already exists in ${oldName}.`);
}
function throwStateDecoratorError(name) {
  throw new Error(`States must be decorated with @State() decorator, but "${name}" isn't.`);
}
function throwActionDecoratorError() {
  throw new Error('@Action() decorator cannot be used with static methods.');
}
function throwSelectorDecoratorError() {
  throw new Error('Selectors only work on methods.');
}
function getZoneWarningMessage() {
  return 'Your application was bootstrapped with nooped zone and your execution strategy requires an actual NgZone!\n' + 'Please set the value of the executionStrategy property to NoopNgxsExecutionStrategy.\n' + 'NgxsModule.forRoot(states, { executionStrategy: NoopNgxsExecutionStrategy })';
}
function getUndecoratedStateWithInjectableWarningMessage(name) {
  return `'${name}' class should be decorated with @Injectable() right after the @State() decorator`;
}
function getInvalidInitializationOrderMessage(addedStates) {
  let message = 'You have an invalid state initialization order. This typically occurs when `NgxsModule.forFeature`\n' + 'or `provideStates` is called before `NgxsModule.forRoot` or `provideStore`.\n' + 'One example is when `NgxsRouterPluginModule.forRoot` is called before `NgxsModule.forRoot`.';
  if (addedStates) {
    const stateNames = Object.keys(addedStates).map(stateName => `"${stateName}"`);
    message += '\nFeature states added before the store initialization is complete: ' + `${stateNames.join(', ')}.`;
  }
  return message;
}
function throwSelectFactoryNotConnectedError() {
  throw new Error('You have forgotten to import the NGXS module!');
}
function throwPatchingArrayError() {
  throw new Error('Patching arrays is not supported.');
}
function throwPatchingPrimitiveError() {
  throw new Error('Patching primitives is not supported.');
}
class DispatchOutsideZoneNgxsExecutionStrategy {
  constructor(_ngZone, _platformId) {
    this._ngZone = _ngZone;
    this._platformId = _platformId;
    if (typeof ngDevMode !== 'undefined' && ngDevMode) {
      verifyZoneIsNotNooped(_ngZone);
    }
  }
  enter(func) {
    if (isPlatformServer(this._platformId)) {
      return this.runInsideAngular(func);
    }
    return this.runOutsideAngular(func);
  }
  leave(func) {
    return this.runInsideAngular(func);
  }
  runInsideAngular(func) {
    if (NgZone.isInAngularZone()) {
      return func();
    }
    return this._ngZone.run(func);
  }
  runOutsideAngular(func) {
    if (NgZone.isInAngularZone()) {
      return this._ngZone.runOutsideAngular(func);
    }
    return func();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function DispatchOutsideZoneNgxsExecutionStrategy_Factory(t) {
      return new (t || DispatchOutsideZoneNgxsExecutionStrategy)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(PLATFORM_ID));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DispatchOutsideZoneNgxsExecutionStrategy,
      factory: DispatchOutsideZoneNgxsExecutionStrategy.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DispatchOutsideZoneNgxsExecutionStrategy, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();
// Caretaker note: this should exist as a separate function and not a class method,
// since class methods are not tree-shakable.
function verifyZoneIsNotNooped(ngZone) {
  // `NoopNgZone` is not exposed publicly as it doesn't expect
  // to be used outside of the core Angular code, thus we just have
  // to check if the zone doesn't extend or instanceof `NgZone`.
  if (ngZone instanceof NgZone) {
    return;
  }
  console.warn(getZoneWarningMessage());
}
const NG_DEV_MODE$8 = typeof ngDevMode !== 'undefined' && ngDevMode;
/**
 * Consumers have the option to utilize the execution strategy provided by
 * `NgxsModule.forRoot({executionStrategy})` or `provideStore([], {executionStrategy})`.
 */
const CUSTOM_NGXS_EXECUTION_STRATEGY = new InjectionToken(NG_DEV_MODE$8 ? 'CUSTOM_NGXS_EXECUTION_STRATEGY' : '');
/**
 * The injection token is used internally to resolve an instance of the execution
 * strategy. It checks whether consumers have provided their own `executionStrategy`
 * and also verifies if we are operating in a zone-aware environment.
 */
const NGXS_EXECUTION_STRATEGY = new InjectionToken(NG_DEV_MODE$8 ? 'NGXS_EXECUTION_STRATEGY' : '', {
  providedIn: 'root',
  factory: () => {
    const ngZone = inject(NgZone);
    const injector = inject(INJECTOR);
    const executionStrategy = injector.get(CUSTOM_NGXS_EXECUTION_STRATEGY);
    const isNgZoneEnabled = ngZone instanceof NgZone;
    return executionStrategy ? injector.get(executionStrategy) : injector.get(isNgZoneEnabled ? DispatchOutsideZoneNgxsExecutionStrategy : NoopNgxsExecutionStrategy);
  }
});
class InternalNgxsExecutionStrategy {
  constructor(_executionStrategy) {
    this._executionStrategy = _executionStrategy;
  }
  enter(func) {
    return this._executionStrategy.enter(func);
  }
  leave(func) {
    return this._executionStrategy.leave(func);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function InternalNgxsExecutionStrategy_Factory(t) {
      return new (t || InternalNgxsExecutionStrategy)(i0.ɵɵinject(NGXS_EXECUTION_STRATEGY));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InternalNgxsExecutionStrategy,
      factory: InternalNgxsExecutionStrategy.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalNgxsExecutionStrategy, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGXS_EXECUTION_STRATEGY]
    }]
  }], null);
})();

/**
 * Composes a array of functions from left to right. Example:
 *
 *      compose([fn, final])(state, action);
 *
 * then the funcs have a signature like:
 *
 *      function fn (state, action, next) {
 *          console.log('here', state, action, next);
 *          return next(state, action);
 *      }
 *
 *      function final (state, action) {
 *          console.log('here', state, action);
 *          return state;
 *      }
 *
 * the last function should not call `next`.
 *
 * @ignore
 */
const compose = funcs => (...args) => {
  const curr = funcs.shift();
  return curr(...args, (...nextArgs) => compose(funcs)(...nextArgs));
};

/**
 * Returns operator that will run
 * `subscribe` outside of the ngxs execution context
 */
function leaveNgxs(ngxsExecutionStrategy) {
  return _wrapObserverCalls(fn => ngxsExecutionStrategy.leave(fn));
}

/**
 * Internal Action stream that is emitted anytime an action is dispatched.
 */
class InternalActions extends _OrderedSubject {
  ngOnDestroy() {
    this.complete();
  }
  /** @nocollapse */
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵInternalActions_BaseFactory;
      return function InternalActions_Factory(t) {
        return (ɵInternalActions_BaseFactory || (ɵInternalActions_BaseFactory = i0.ɵɵgetInheritedFactory(InternalActions)))(t || InternalActions);
      };
    })();
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InternalActions,
      factory: InternalActions.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalActions, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
/**
 * Action stream that is emitted anytime an action is dispatched.
 *
 * You can listen to this in services to react without stores.
 */
class Actions extends Observable {
  constructor(internalActions$, internalExecutionStrategy) {
    const sharedInternalActions$ = internalActions$.pipe(leaveNgxs(internalExecutionStrategy),
    // The `InternalActions` subject emits outside of the Angular zone.
    // We have to re-enter the Angular zone for any incoming consumer.
    // The `share()` operator reduces the number of change detections.
    // This would call leave only once for any stream emission across all active subscribers.
    share());
    super(observer => {
      const childSubscription = sharedInternalActions$.subscribe({
        next: ctx => observer.next(ctx),
        error: error => observer.error(error),
        complete: () => observer.complete()
      });
      observer.add(childSubscription);
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function Actions_Factory(t) {
      return new (t || Actions)(i0.ɵɵinject(InternalActions), i0.ɵɵinject(InternalNgxsExecutionStrategy));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Actions,
      factory: Actions.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Actions, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: InternalActions
  }, {
    type: InternalNgxsExecutionStrategy
  }], null);
})();
class PluginManager {
  constructor(_parentManager, _pluginHandlers) {
    this._parentManager = _parentManager;
    this._pluginHandlers = _pluginHandlers;
    this.plugins = [];
    this.registerHandlers();
  }
  get rootPlugins() {
    return this._parentManager && this._parentManager.plugins || this.plugins;
  }
  registerHandlers() {
    const pluginHandlers = this.getPluginHandlers();
    this.rootPlugins.push(...pluginHandlers);
  }
  getPluginHandlers() {
    const handlers = this._pluginHandlers || [];
    return handlers.map(plugin => plugin.handle ? plugin.handle.bind(plugin) : plugin);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function PluginManager_Factory(t) {
      return new (t || PluginManager)(i0.ɵɵinject(PluginManager, 12), i0.ɵɵinject(NGXS_PLUGINS, 8));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PluginManager,
      factory: PluginManager.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PluginManager, [{
    type: Injectable
  }], () => [{
    type: PluginManager,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGXS_PLUGINS]
    }, {
      type: Optional
    }]
  }], null);
})();
const ɵɵunhandledRxjsErrorCallbacks = new WeakMap();
const existingHandler = config.onUnhandledError;
config.onUnhandledError = function (error) {
  const unhandledErrorCallback = ɵɵunhandledRxjsErrorCallbacks.get(error);
  if (unhandledErrorCallback) {
    unhandledErrorCallback();
  } else if (existingHandler) {
    existingHandler.call(this, error);
  } else {
    throw error;
  }
};
function executeUnhandledCallback(error) {
  const unhandledErrorCallback = ɵɵunhandledRxjsErrorCallbacks.get(error);
  if (unhandledErrorCallback) {
    unhandledErrorCallback();
    return true;
  }
  return false;
}
function assignUnhandledCallback(error, callback) {
  // Since the error can be essentially anything, we must ensure that we only
  // handle objects, as weak maps do not allow any other key type besides objects.
  // The error can also be a string if thrown in the following manner: `throwError('My Error')`.
  if (error !== null && typeof error === 'object') {
    let hasBeenCalled = false;
    ɵɵunhandledRxjsErrorCallbacks.set(error, () => {
      if (!hasBeenCalled) {
        hasBeenCalled = true;
        callback();
      }
    });
  }
  return error;
}
function fallbackSubscriber(ngZone) {
  return source => {
    let subscription = source.subscribe({
      error: error => {
        ngZone.runOutsideAngular(() => {
          // This is necessary to schedule a microtask to ensure that synchronous
          // errors are not reported before the real subscriber arrives. If an error
          // is thrown synchronously in any action, it will be reported to the error
          // handler regardless. Since RxJS reports unhandled errors asynchronously,
          // implementing a microtask ensures that we are also safe in this scenario.
          queueMicrotask(() => {
            if (subscription) {
              executeUnhandledCallback(error);
            }
          });
        });
      }
    });
    return new Observable(subscriber => {
      // Now that there is a real subscriber, we can unsubscribe our pro-active subscription
      subscription?.unsubscribe();
      subscription = null;
      return source.subscribe(subscriber);
    });
  };
}

/**
 * Internal Action result stream that is emitted when an action is completed.
 * This is used as a method of returning the action result to the dispatcher
 * for the observable returned by the dispatch(...) call.
 * The dispatcher then asynchronously pushes the result from this stream onto the main action stream as a result.
 */
class InternalDispatchedActionResults extends Subject {
  /** @nocollapse */static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵInternalDispatchedActionResults_BaseFactory;
      return function InternalDispatchedActionResults_Factory(t) {
        return (ɵInternalDispatchedActionResults_BaseFactory || (ɵInternalDispatchedActionResults_BaseFactory = i0.ɵɵgetInheritedFactory(InternalDispatchedActionResults)))(t || InternalDispatchedActionResults);
      };
    })();
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InternalDispatchedActionResults,
      factory: InternalDispatchedActionResults.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalDispatchedActionResults, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class InternalDispatcher {
  constructor(_ngZone, _actions, _actionResults, _pluginManager, _stateStream, _ngxsExecutionStrategy) {
    this._ngZone = _ngZone;
    this._actions = _actions;
    this._actionResults = _actionResults;
    this._pluginManager = _pluginManager;
    this._stateStream = _stateStream;
    this._ngxsExecutionStrategy = _ngxsExecutionStrategy;
  }
  /**
   * Dispatches event(s).
   */
  dispatch(actionOrActions) {
    const result = this._ngxsExecutionStrategy.enter(() => this.dispatchByEvents(actionOrActions));
    return result.pipe(fallbackSubscriber(this._ngZone), leaveNgxs(this._ngxsExecutionStrategy));
  }
  dispatchByEvents(actionOrActions) {
    if (Array.isArray(actionOrActions)) {
      if (actionOrActions.length === 0) return of(undefined);
      return forkJoin(actionOrActions.map(action => this.dispatchSingle(action))).pipe(map(() => undefined));
    } else {
      return this.dispatchSingle(actionOrActions);
    }
  }
  dispatchSingle(action) {
    if (typeof ngDevMode !== 'undefined' && ngDevMode) {
      const type = getActionTypeFromInstance(action);
      if (!type) {
        const error = new Error(`This action doesn't have a type property: ${action.constructor.name}`);
        return throwError(() => error);
      }
    }
    const prevState = this._stateStream.getValue();
    const plugins = this._pluginManager.plugins;
    return compose([...plugins, (nextState, nextAction) => {
      if (nextState !== prevState) {
        this._stateStream.next(nextState);
      }
      const actionResult$ = this.getActionResultStream(nextAction);
      actionResult$.subscribe(ctx => this._actions.next(ctx));
      this._actions.next({
        action: nextAction,
        status: "DISPATCHED" /* ActionStatus.Dispatched */
      });
      return this.createDispatchObservable(actionResult$);
    }])(prevState, action).pipe(shareReplay());
  }
  getActionResultStream(action) {
    return this._actionResults.pipe(filter(ctx => ctx.action === action && ctx.status !== "DISPATCHED" /* ActionStatus.Dispatched */), take(1), shareReplay());
  }
  createDispatchObservable(actionResult$) {
    return actionResult$.pipe(exhaustMap(ctx => {
      switch (ctx.status) {
        case "SUCCESSFUL" /* ActionStatus.Successful */:
          // The `createDispatchObservable` function should return the
          // state, as its result is utilized by plugins.
          return of(this._stateStream.getValue());
        case "ERRORED" /* ActionStatus.Errored */:
          return throwError(() => ctx.error);
        default:
          return EMPTY;
      }
    })).pipe(shareReplay());
  }
  /** @nocollapse */
  static {
    this.ɵfac = function InternalDispatcher_Factory(t) {
      return new (t || InternalDispatcher)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(InternalActions), i0.ɵɵinject(InternalDispatchedActionResults), i0.ɵɵinject(PluginManager), i0.ɵɵinject(i1.ɵStateStream), i0.ɵɵinject(InternalNgxsExecutionStrategy));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InternalDispatcher,
      factory: InternalDispatcher.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalDispatcher, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: InternalActions
  }, {
    type: InternalDispatchedActionResults
  }, {
    type: PluginManager
  }, {
    type: i1.ɵStateStream
  }, {
    type: InternalNgxsExecutionStrategy
  }], null);
})();
const NG_DEV_MODE$7 = typeof ngDevMode !== 'undefined' && ngDevMode;
// The injection token is used to resolve a list of states provided at
// the root level through either `NgxsModule.forRoot` or `provideStore`.
const ROOT_STATE_TOKEN = new InjectionToken(NG_DEV_MODE$7 ? 'ROOT_STATE_TOKEN' : '');
// The injection token is used to resolve a list of states provided at
// the feature level through either `NgxsModule.forFeature` or `provideStates`.
// The Array<Array> is used to overload the resolved value of the token because
// it is a multi-provider token.
const FEATURE_STATE_TOKEN = new InjectionToken(NG_DEV_MODE$7 ? 'FEATURE_STATE_TOKEN' : '');
// The injection token is used to resolve to options provided at the root
// level through either `NgxsModule.forRoot` or `provideStore`.
const NGXS_OPTIONS = new InjectionToken(NG_DEV_MODE$7 ? 'NGXS_OPTIONS' : '');
/**
 * The NGXS config settings.
 */
class NgxsConfig {
  constructor() {
    this.compatibility = {
      strictContentSecurityPolicy: false
    };
    /**
     * Determines the execution context to perform async operations inside. An implementation can be
     * provided to override the default behaviour where the async operations are run
     * outside Angular's zone but all observable behaviours of NGXS are run back inside Angular's zone.
     * These observable behaviours are from:
     *   `store.selectSignal(...)`, `store.select(...)`, `actions.subscribe(...)` or `store.dispatch(...).subscribe(...)`
     * Every `zone.run` causes Angular to run change detection on the whole tree (`app.tick()`) so of your
     * application doesn't rely on zone.js running change detection then you can switch to the
     * `NoopNgxsExecutionStrategy` that doesn't interact with zones.
     * (default: null)
     */
    this.executionStrategy = DispatchOutsideZoneNgxsExecutionStrategy;
    /**
     * Defining shared selector options
     */
    this.selectorOptions = {
      injectContainerState: false,
      suppressErrors: false
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsConfig_Factory(t) {
      return new (t || NgxsConfig)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxsConfig,
      factory: () => (() => {
        const defaultConfig = new NgxsConfig();
        const config = inject(NGXS_OPTIONS);
        return {
          ...defaultConfig,
          ...config,
          selectorOptions: {
            ...defaultConfig.selectorOptions,
            ...config.selectorOptions
          }
        };
      })(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => {
        const defaultConfig = new NgxsConfig();
        const config = inject(NGXS_OPTIONS);
        return {
          ...defaultConfig,
          ...config,
          selectorOptions: {
            ...defaultConfig.selectorOptions,
            ...config.selectorOptions
          }
        };
      }
    }]
  }], null, null);
})();
/**
 * Represents a basic change from a previous to a new value for a single state instance.
 * Passed as a value in a NgxsSimpleChanges object to the ngxsOnChanges hook.
 */
class NgxsSimpleChange {
  constructor(previousValue, currentValue, firstChange) {
    this.previousValue = previousValue;
    this.currentValue = currentValue;
    this.firstChange = firstChange;
  }
}

/**
 * Object freeze code
 * https://github.com/jsdf/deep-freeze
 */
const deepFreeze = o => {
  Object.freeze(o);
  const oIsFunction = typeof o === 'function';
  const hasOwnProp = Object.prototype.hasOwnProperty;
  Object.getOwnPropertyNames(o).forEach(function (prop) {
    if (hasOwnProp.call(o, prop) && (oIsFunction ? prop !== 'caller' && prop !== 'callee' && prop !== 'arguments' : true) && o[prop] !== null && (typeof o[prop] === 'object' || typeof o[prop] === 'function') && !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop]);
    }
  });
  return o;
};

/**
 * @ignore
 */
class InternalStateOperations {
  constructor(_stateStream, _dispatcher, _config) {
    this._stateStream = _stateStream;
    this._dispatcher = _dispatcher;
    this._config = _config;
  }
  /**
   * Returns the root state operators.
   */
  getRootStateOperations() {
    const rootStateOperations = {
      getState: () => this._stateStream.getValue(),
      setState: newState => this._stateStream.next(newState),
      dispatch: actionOrActions => this._dispatcher.dispatch(actionOrActions)
    };
    if (typeof ngDevMode !== 'undefined' && ngDevMode) {
      return this._config.developmentMode ? ensureStateAndActionsAreImmutable(rootStateOperations) : rootStateOperations;
    } else {
      return rootStateOperations;
    }
  }
  setStateToTheCurrentWithNew(results) {
    const stateOperations = this.getRootStateOperations();
    // Get our current stream
    const currentState = stateOperations.getState();
    // Set the state to the current + new
    stateOperations.setState({
      ...currentState,
      ...results.defaults
    });
  }
  /** @nocollapse */
  static {
    this.ɵfac = function InternalStateOperations_Factory(t) {
      return new (t || InternalStateOperations)(i0.ɵɵinject(i1.ɵStateStream), i0.ɵɵinject(InternalDispatcher), i0.ɵɵinject(NgxsConfig));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InternalStateOperations,
      factory: InternalStateOperations.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalStateOperations, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ɵStateStream
  }, {
    type: InternalDispatcher
  }, {
    type: NgxsConfig
  }], null);
})();
function ensureStateAndActionsAreImmutable(root) {
  return {
    getState: () => root.getState(),
    setState: value => {
      const frozenValue = deepFreeze(value);
      return root.setState(frozenValue);
    },
    dispatch: actions => {
      return root.dispatch(actions);
    }
  };
}
const NG_DEV_MODE$6 = typeof ngDevMode !== 'undefined' && ngDevMode;
function createRootSelectorFactory(selectorMetaData, selectors, memoizedSelectorFn) {
  return context => {
    const {
      argumentSelectorFunctions,
      selectorOptions
    } = getRuntimeSelectorInfo(context, selectorMetaData, selectors);
    const {
      suppressErrors
    } = selectorOptions;
    return function selectFromRoot(rootState) {
      // Determine arguments from the app state using the selectors
      const results = argumentSelectorFunctions.map(argFn => argFn(rootState));
      // If the lambda attempts to access something in the state that doesn't exist,
      // it will throw a `TypeError`. Since this behavior is common, we simply return
      // `undefined` in such cases.
      try {
        return memoizedSelectorFn(...results);
      } catch (ex) {
        if (suppressErrors && ex instanceof TypeError) {
          return undefined;
        }
        // We're logging an error in this function because it may be used by `select`,
        // `selectSignal`, and `selectSnapshot`. Therefore, there's no need to catch
        // exceptions there to log errors.
        if (NG_DEV_MODE$6) {
          const message = 'The selector below has thrown an error upon invocation. ' + 'Please check for any unsafe property access that may result in null ' + 'or undefined values.';
          // Avoid concatenating the message with the original function, as this will
          // invoke `toString()` on the function. Instead, log it as the second argument.
          // This way, developers will be able to navigate to the actual code in the browser.
          console.error(message, selectorMetaData.originalFn);
        }
        throw ex;
      }
    };
  };
}
function createMemoizedSelectorFn(originalFn, creationMetadata) {
  const containerClass = creationMetadata && creationMetadata.containerClass;
  const wrappedFn = function wrappedSelectorFn(...args) {
    const returnValue = originalFn.apply(containerClass, args);
    if (returnValue instanceof Function) {
      const innerMemoizedFn = _memoize.apply(null, [returnValue]);
      return innerMemoizedFn;
    }
    return returnValue;
  };
  const memoizedFn = _memoize(wrappedFn);
  Object.setPrototypeOf(memoizedFn, originalFn);
  return memoizedFn;
}
function getRuntimeSelectorInfo(context, selectorMetaData, selectors = []) {
  const localSelectorOptions = selectorMetaData.getSelectorOptions();
  const selectorOptions = context.getSelectorOptions(localSelectorOptions);
  const selectorsToApply = getSelectorsToApply(selectors, selectorOptions, selectorMetaData.containerClass);
  const argumentSelectorFunctions = selectorsToApply.map(selector => {
    const factory = getRootSelectorFactory(selector);
    return factory(context);
  });
  return {
    selectorOptions,
    argumentSelectorFunctions
  };
}
function getSelectorsToApply(selectors = [], selectorOptions, containerClass) {
  const selectorsToApply = [];
  // The container state refers to the state class that includes the
  // definition of the selector function, for example:
  // @State()
  // class AnimalsState {
  //   @Selector()
  //   static getAnimals(state: AnimalsStateModel) {}
  // }
  // The `AnimalsState` serves as the container state. Additionally, the
  // selector may reside within a namespace or another class lacking the
  // `@State` decorator, thus not being treated as the container state.
  const canInjectContainerState = selectorOptions.injectContainerState || selectors.length === 0;
  if (containerClass && canInjectContainerState) {
    // If we are on a state class, add it as the first selector parameter
    const metadata = _getStoreMetadata(containerClass);
    if (metadata) {
      selectorsToApply.push(containerClass);
    }
  }
  selectorsToApply.push(...selectors);
  return selectorsToApply;
}
/**
 * This function gets the factory function to create the selector to get the selected slice from the app state
 * @ignore
 */
function getRootSelectorFactory(selector) {
  const metadata = _getSelectorMetadata(selector) || _getStoreMetadata(selector);
  return metadata && metadata.makeRootSelector || (() => selector);
}
const NG_DEV_MODE$5 = typeof ngDevMode !== 'undefined' && ngDevMode;
/**
 * Get a deeply nested value. Example:
 *
 *    getValue({ foo: bar: [] }, 'foo.bar') //=> []
 *
 * Note: This is not as fast as the `fastPropGetter` but is strict Content Security Policy compliant.
 * See perf hit: https://jsperf.com/fast-value-getter-given-path/1
 *
 * @ignore
 */
function compliantPropGetter(paths) {
  return obj => {
    for (let i = 0; i < paths.length; i++) {
      if (!obj) return undefined;
      obj = obj[paths[i]];
    }
    return obj;
  };
}
/**
 * The generated function is faster than:
 * - pluck (Observable operator)
 * - memoize
 *
 * @ignore
 */
function fastPropGetter(paths) {
  const segments = paths;
  let seg = 'store.' + segments[0];
  let i = 0;
  const l = segments.length;
  let expr = seg;
  while (++i < l) {
    expr = expr + ' && ' + (seg = seg + '.' + segments[i]);
  }
  const fn = new Function('store', 'return ' + expr + ';');
  return fn;
}
/**
 * Get a deeply nested value. Example:
 *
 *    getValue({ foo: bar: [] }, 'foo.bar') //=> []
 *
 * @ignore
 *
 * Marked for removal. It's only used within `createSelectorFn`.
 */
function propGetter(paths, config) {
  if (config?.compatibility?.strictContentSecurityPolicy) {
    return compliantPropGetter(paths);
  } else {
    return fastPropGetter(paths);
  }
}
// This injection token selects the prop getter implementation once the app is
// bootstrapped, as the `propGetter` function's behavior determines the implementation
// each time it's called. It accepts the config as the second argument. We no longer
// need to check for the `strictContentSecurityPolicy` every time the prop getter
// implementation is selected. Now, the `propGetter` function is only used within
// `createSelectorFn`, which, in turn, is solely used by the `Select` decorator.
// We've been trying to deprecate the `Select` decorator because it's unstable with
// server-side rendering and micro-frontend applications.
const ɵPROP_GETTER = new InjectionToken(NG_DEV_MODE$5 ? 'PROP_GETTER' : '', {
  providedIn: 'root',
  factory: () => inject(NgxsConfig).compatibility?.strictContentSecurityPolicy ? compliantPropGetter : fastPropGetter
});
/**
 * Given an array of states, it will return a object graph. Example:
 *    const states = [
 *      Cart,
 *      CartSaved,
 *      CartSavedItems
 *    ]
 *
 * would return:
 *
 *  const graph = {
 *    cart: ['saved'],
 *    saved: ['items'],
 *    items: []
 *  };
 *
 * @ignore
 */
function buildGraph(stateClasses) {
  const findName = stateClass => {
    const meta = stateClasses.find(g => g === stateClass);
    if (NG_DEV_MODE$5 && !meta) {
      throw new Error(`Child state not found: ${stateClass}. \r\nYou may have forgotten to add states to module`);
    }
    return meta[_META_KEY].name;
  };
  return stateClasses.reduce((result, stateClass) => {
    const {
      name,
      children
    } = stateClass[_META_KEY];
    result[name] = (children || []).map(findName);
    return result;
  }, {});
}
/**
 * Given a states array, returns object graph
 * returning the name and state metadata. Example:
 *
 *  const graph = {
 *    cart: { metadata }
 *  };
 *
 * @ignore
 */
function nameToState(states) {
  return states.reduce((result, stateClass) => {
    const meta = stateClass[_META_KEY];
    result[meta.name] = stateClass;
    return result;
  }, {});
}
/**
 * Given a object relationship graph will return the full path
 * for the child items. Example:
 *
 *  const graph = {
 *    cart: ['saved'],
 *    saved: ['items'],
 *    items: []
 *  };
 *
 * would return:
 *
 *  const r = {
 *    cart: 'cart',
 *    saved: 'cart.saved',
 *    items: 'cart.saved.items'
 *  };
 *
 * @ignore
 */
function findFullParentPath(obj, newObj = {}) {
  const visit = (child, keyToFind) => {
    for (const key in child) {
      if (child.hasOwnProperty(key) && child[key].indexOf(keyToFind) >= 0) {
        const parent = visit(child, key);
        return parent !== null ? `${parent}.${key}` : key;
      }
    }
    return null;
  };
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const parent = visit(obj, key);
      newObj[key] = parent ? `${parent}.${key}` : key;
    }
  }
  return newObj;
}
/**
 * Given a object graph, it will return the items topologically sorted Example:
 *
 *  const graph = {
 *    cart: ['saved'],
 *    saved: ['items'],
 *    items: []
 *  };
 *
 * would return:
 *
 *  const results = [
 *    'items',
 *    'saved',
 *    'cart'
 *  ];
 *
 * @ignore
 */
function topologicalSort(graph) {
  const sorted = [];
  const visited = {};
  const visit = (name, ancestors = []) => {
    if (!Array.isArray(ancestors)) {
      ancestors = [];
    }
    ancestors.push(name);
    visited[name] = true;
    graph[name].forEach(dep => {
      if (NG_DEV_MODE$5 && ancestors.indexOf(dep) >= 0) {
        throw new Error(`Circular dependency '${dep}' is required by '${name}': ${ancestors.join(' -> ')}`);
      }
      if (visited[dep]) {
        return;
      }
      visit(dep, ancestors.slice(0));
    });
    if (sorted.indexOf(name) < 0) {
      sorted.push(name);
    }
  };
  Object.keys(graph).forEach(k => visit(k));
  return sorted.reverse();
}

/**
 * RxJS operator for selecting out specific actions.
 *
 * This will grab actions that have just been dispatched as well as actions that have completed
 */
function ofAction(...allowedTypes) {
  return ofActionOperator(allowedTypes);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just been dispatched
 */
function ofActionDispatched(...allowedTypes) {
  return ofActionOperator(allowedTypes, ["DISPATCHED" /* ActionStatus.Dispatched */]);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just been successfully completed
 */
function ofActionSuccessful(...allowedTypes) {
  return ofActionOperator(allowedTypes, ["SUCCESSFUL" /* ActionStatus.Successful */]);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just been canceled
 */
function ofActionCanceled(...allowedTypes) {
  return ofActionOperator(allowedTypes, ["CANCELED" /* ActionStatus.Canceled */]);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just been completed
 */
function ofActionCompleted(...allowedTypes) {
  const allowedStatuses = ["SUCCESSFUL" /* ActionStatus.Successful */, "CANCELED" /* ActionStatus.Canceled */, "ERRORED" /* ActionStatus.Errored */];
  return ofActionOperator(allowedTypes, allowedStatuses, mapActionResult);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just thrown an error
 */
function ofActionErrored(...allowedTypes) {
  return ofActionOperator(allowedTypes, ["ERRORED" /* ActionStatus.Errored */], mapActionResult);
}
function ofActionOperator(allowedTypes, statuses,
// This could have been written as
// `OperatorFunction<ActionContext, ActionCompletion | any>`, as it maps
// either to `ctx.action` or to `ActionCompletion`. However,
// `ActionCompletion | any` defaults to `any`, rendering the union
// type meaningless.
mapOperator = mapAction) {
  const allowedMap = createAllowedActionTypesMap(allowedTypes);
  const allowedStatusMap = statuses && createAllowedStatusesMap(statuses);
  return function (o) {
    return o.pipe(filterStatus(allowedMap, allowedStatusMap), mapOperator());
  };
}
function filterStatus(allowedTypes, allowedStatuses) {
  return filter(ctx => {
    const actionType = getActionTypeFromInstance(ctx.action);
    const typeMatch = allowedTypes[actionType];
    const statusMatch = allowedStatuses ? allowedStatuses[ctx.status] : true;
    return typeMatch && statusMatch;
  });
}
function mapActionResult() {
  return map(({
    action,
    status,
    error
  }) => {
    return {
      action,
      result: {
        successful: "SUCCESSFUL" /* ActionStatus.Successful */ === status,
        canceled: "CANCELED" /* ActionStatus.Canceled */ === status,
        error
      }
    };
  });
}
function mapAction() {
  return map(ctx => ctx.action);
}
function createAllowedActionTypesMap(types) {
  return types.reduce((filterMap, klass) => {
    filterMap[getActionTypeFromInstance(klass)] = true;
    return filterMap;
  }, {});
}
function createAllowedStatusesMap(statuses) {
  return statuses.reduce((filterMap, status) => {
    filterMap[status] = true;
    return filterMap;
  }, {});
}
function simplePatch(value) {
  return existingState => {
    if (typeof ngDevMode !== 'undefined' && ngDevMode) {
      if (Array.isArray(value)) {
        throwPatchingArrayError();
      } else if (typeof value !== 'object') {
        throwPatchingPrimitiveError();
      }
    }
    const newState = {
      ...existingState
    };
    for (const key in value) {
      // deep clone for patch compatibility
      newState[key] = value[key];
    }
    return newState;
  };
}

/**
 * State Context factory class
 * @ignore
 */
class StateContextFactory {
  constructor(_internalStateOperations) {
    this._internalStateOperations = _internalStateOperations;
  }
  /**
   * Create the state context
   */
  createStateContext(mappedStore) {
    const root = this._internalStateOperations.getRootStateOperations();
    return {
      getState() {
        const currentAppState = root.getState();
        return getState(currentAppState, mappedStore.path);
      },
      patchState(val) {
        const currentAppState = root.getState();
        const patchOperator = simplePatch(val);
        setStateFromOperator(root, currentAppState, patchOperator, mappedStore.path);
      },
      setState(val) {
        const currentAppState = root.getState();
        if (isStateOperator(val)) {
          setStateFromOperator(root, currentAppState, val, mappedStore.path);
        } else {
          setStateValue(root, currentAppState, val, mappedStore.path);
        }
      },
      dispatch(actions) {
        return root.dispatch(actions);
      }
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function StateContextFactory_Factory(t) {
      return new (t || StateContextFactory)(i0.ɵɵinject(InternalStateOperations));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StateContextFactory,
      factory: StateContextFactory.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StateContextFactory, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: InternalStateOperations
  }], null);
})();
function setStateValue(root, currentAppState, newValue, path) {
  const newAppState = setValue(currentAppState, path, newValue);
  root.setState(newAppState);
  return newAppState;
  // In doing this refactoring I noticed that there is a 'bug' where the
  // application state is returned instead of this state slice.
  // This has worked this way since the beginning see:
  // https://github.com/ngxs/store/blame/324c667b4b7debd8eb979006c67ca0ae347d88cd/src/state-factory.ts
  // This needs to be fixed, but is a 'breaking' change.
  // I will do this fix in a subsequent PR and we can decide how to handle it.
}
function setStateFromOperator(root, currentAppState, stateOperator, path) {
  const local = getState(currentAppState, path);
  const newValue = stateOperator(local);
  return setStateValue(root, currentAppState, newValue, path);
}
function getState(currentAppState, path) {
  return getValue(currentAppState, path);
}
const stateNameRegex = new RegExp('^[a-zA-Z0-9_]+$');
function ensureStateNameIsValid(name) {
  if (!name) {
    throwStateNamePropertyError();
  } else if (!stateNameRegex.test(name)) {
    throwStateNameError(name);
  }
}
function ensureStateNameIsUnique(stateName, state, statesByName) {
  const existingState = statesByName[stateName];
  if (existingState && existingState !== state) {
    throwStateUniqueError(stateName, state.name, existingState.name);
  }
}
function ensureStatesAreDecorated(stateClasses) {
  stateClasses.forEach(stateClass => {
    if (!_getStoreMetadata(stateClass)) {
      throwStateDecoratorError(stateClass.name);
    }
  });
}

/**
 * All provided or injected tokens must have `@Injectable` decorator
 * (previously, injected tokens without `@Injectable` were allowed
 * if another decorator was used, e.g. pipes).
 */
function ensureStateClassIsInjectable(stateClass) {
  if (jit_hasInjectableAnnotation(stateClass) || aot_hasNgInjectableDef(stateClass)) {
    return;
  }
  console.warn(getUndecoratedStateWithInjectableWarningMessage(stateClass.name));
}
function aot_hasNgInjectableDef(stateClass) {
  // `ɵprov` is a static property added by the NGCC compiler. It always exists in
  // AOT mode because this property is added before runtime. If an application is running in
  // JIT mode then this property can be added by the `@Injectable()` decorator. The `@Injectable()`
  // decorator has to go after the `@State()` decorator, thus we prevent users from unwanted DI errors.
  return !!stateClass.ɵprov;
}
function jit_hasInjectableAnnotation(stateClass) {
  // `ɵprov` doesn't exist in JIT mode (for instance when running unit tests with Jest).
  const annotations = stateClass.__annotations__ || [];
  return annotations.some(annotation => annotation?.ngMetadataName === 'Injectable');
}
const NG_DEV_MODE$4 = typeof ngDevMode !== 'undefined' && ngDevMode;
const NGXS_DEVELOPMENT_OPTIONS = new InjectionToken(NG_DEV_MODE$4 ? 'NGXS_DEVELOPMENT_OPTIONS' : '', {
  providedIn: 'root',
  factory: () => ({
    warnOnUnhandledActions: true
  })
});
class NgxsUnhandledActionsLogger {
  constructor(options) {
    /**
     * These actions should be ignored by default; the user can increase this
     * list in the future via the `ignoreActions` method.
     */
    this._ignoredActions = new Set([InitState.type, UpdateState.type]);
    if (typeof options.warnOnUnhandledActions === 'object') {
      this.ignoreActions(...options.warnOnUnhandledActions.ignore);
    }
  }
  /**
   * Adds actions to the internal list of actions that should be ignored.
   */
  ignoreActions(...actions) {
    for (const action of actions) {
      this._ignoredActions.add(action.type);
    }
  }
  /** @internal */
  warn(action) {
    const actionShouldBeIgnored = Array.from(this._ignoredActions).some(type => type === getActionTypeFromInstance(action));
    if (actionShouldBeIgnored) {
      return;
    }
    action = action.constructor && action.constructor.name !== 'Object' ? action.constructor.name : action.type;
    console.warn(`The ${action} action has been dispatched but hasn't been handled. This may happen if the state with an action handler for this action is not registered.`);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsUnhandledActionsLogger_Factory(t) {
      return new (t || NgxsUnhandledActionsLogger)(i0.ɵɵinject(NGXS_DEVELOPMENT_OPTIONS));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxsUnhandledActionsLogger,
      factory: NgxsUnhandledActionsLogger.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsUnhandledActionsLogger, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGXS_DEVELOPMENT_OPTIONS]
    }]
  }], null);
})();
class NgxsUnhandledErrorHandler {
  constructor() {
    this._ngZone = inject(NgZone);
    this._errorHandler = inject(ErrorHandler);
  }
  /**
   * The `_unhandledErrorContext` is left unused internally since we do not
   * require it for internal operations. However, developers who wish to provide
   * their own custom error handler may utilize this context information.
   */
  handleError(error, _unhandledErrorContext) {
    // In order to avoid duplicate error handling, it is necessary to leave
    // the Angular zone to ensure that errors are not caught twice. The `handleError`
    // method may contain a `throw error` statement, which is used to re-throw the error.
    // If the error is re-thrown within the Angular zone, it will be caught again by the
    // Angular zone. By default, `@angular/core` leaves the Angular zone when invoking
    // `handleError` (see `_callAndReportToErrorHandler`).
    this._ngZone.runOutsideAngular(() => this._errorHandler.handleError(error));
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsUnhandledErrorHandler_Factory(t) {
      return new (t || NgxsUnhandledErrorHandler)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxsUnhandledErrorHandler,
      factory: NgxsUnhandledErrorHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsUnhandledErrorHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const NG_DEV_MODE$3 = typeof ngDevMode !== 'undefined' && ngDevMode;
function cloneDefaults(defaults) {
  let value = defaults === undefined ? {} : defaults;
  if (defaults) {
    if (Array.isArray(defaults)) {
      value = defaults.slice();
    } else if (typeof defaults === 'object') {
      value = {
        ...defaults
      };
    }
  }
  return value;
}
/**
 * The `StateFactory` class adds root and feature states to the graph.
 * This extracts state names from state classes, checks if they already
 * exist in the global graph, throws errors if their names are invalid, etc.
 * See its constructor, state factories inject state factories that are
 * parent-level providers. This is required to get feature states from the
 * injector on the same level.
 *
 * The `NgxsModule.forFeature(...)` returns `providers: [StateFactory, ...states]`.
 * The `StateFactory` is initialized on the feature level and goes through `...states`
 * to get them from the injector through `injector.get(state)`.
 * @ignore
 */
class StateFactory {
  constructor(_injector, _config, _parentFactory, _actions, _actionResults, _stateContextFactory, _initialState) {
    this._injector = _injector;
    this._config = _config;
    this._parentFactory = _parentFactory;
    this._actions = _actions;
    this._actionResults = _actionResults;
    this._stateContextFactory = _stateContextFactory;
    this._initialState = _initialState;
    this._actionsSubscription = null;
    this._propGetter = inject(ɵPROP_GETTER);
    this._ngxsUnhandledErrorHandler = null;
    this._states = [];
    this._statesByName = {};
    this._statePaths = {};
    this.getRuntimeSelectorContext = _memoize(() => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const stateFactory = this;
      const propGetter = stateFactory._propGetter;
      function resolveGetter(key) {
        const path = stateFactory.statePaths[key];
        return path ? propGetter(path.split('.')) : null;
      }
      const context = this._parentFactory ? this._parentFactory.getRuntimeSelectorContext() : {
        getStateGetter(key) {
          // Use `@__INLINE__` annotation to forcely inline `resolveGetter`.
          // This is a Terser annotation, which will function only in the production mode.
          let getter = /*@__INLINE__*/resolveGetter(key);
          if (getter) {
            return getter;
          }
          return (...args) => {
            // Late loaded getter
            if (!getter) {
              getter = /*@__INLINE__*/resolveGetter(key);
            }
            return getter ? getter(...args) : undefined;
          };
        },
        getSelectorOptions(localOptions) {
          const globalSelectorOptions = stateFactory._config.selectorOptions;
          return {
            ...globalSelectorOptions,
            ...(localOptions || {})
          };
        }
      };
      return context;
    });
  }
  get states() {
    return this._parentFactory ? this._parentFactory.states : this._states;
  }
  get statesByName() {
    return this._parentFactory ? this._parentFactory.statesByName : this._statesByName;
  }
  get statePaths() {
    return this._parentFactory ? this._parentFactory.statePaths : this._statePaths;
  }
  ngOnDestroy() {
    this._actionsSubscription?.unsubscribe();
  }
  /**
   * Add a new state to the global defs.
   */
  add(stateClasses) {
    if (NG_DEV_MODE$3) {
      ensureStatesAreDecorated(stateClasses);
    }
    const {
      newStates
    } = this.addToStatesMap(stateClasses);
    if (!newStates.length) return [];
    const stateGraph = buildGraph(newStates);
    const sortedStates = topologicalSort(stateGraph);
    const paths = findFullParentPath(stateGraph);
    const nameGraph = nameToState(newStates);
    const bootstrappedStores = [];
    for (const name of sortedStates) {
      const stateClass = nameGraph[name];
      const path = paths[name];
      const meta = stateClass[_META_KEY];
      this.addRuntimeInfoToMeta(meta, path);
      // Note: previously we called `ensureStateClassIsInjectable` within the
      // `State` decorator. This check is moved here because the `ɵprov` property
      // will not exist on the class in JIT mode (because it's set asynchronously
      // during JIT compilation through `Object.defineProperty`).
      if (NG_DEV_MODE$3) {
        ensureStateClassIsInjectable(stateClass);
      }
      const stateMap = {
        name,
        path,
        isInitialised: false,
        actions: meta.actions,
        instance: this._injector.get(stateClass),
        defaults: cloneDefaults(meta.defaults)
      };
      // ensure our store hasn't already been added
      // but don't throw since it could be lazy
      // loaded from different paths
      if (!this.hasBeenMountedAndBootstrapped(name, path)) {
        bootstrappedStores.push(stateMap);
      }
      this.states.push(stateMap);
    }
    return bootstrappedStores;
  }
  /**
   * Add a set of states to the store and return the defaults
   */
  addAndReturnDefaults(stateClasses) {
    const classes = stateClasses || [];
    const mappedStores = this.add(classes);
    const defaults = mappedStores.reduce((result, mappedStore) => setValue(result, mappedStore.path, mappedStore.defaults), {});
    return {
      defaults,
      states: mappedStores
    };
  }
  connectActionHandlers() {
    // Note: We have to connect actions only once when the `StateFactory`
    //       is being created for the first time. This checks if we're in
    //       a child state factory and the parent state factory already exists.
    if (this._parentFactory || this._actionsSubscription !== null) {
      return;
    }
    const dispatched$ = new Subject();
    this._actionsSubscription = this._actions.pipe(filter(ctx => ctx.status === "DISPATCHED" /* ActionStatus.Dispatched */), mergeMap(ctx => {
      dispatched$.next(ctx);
      const action = ctx.action;
      return this.invokeActions(dispatched$, action).pipe(map(() => ({
        action,
        status: "SUCCESSFUL" /* ActionStatus.Successful */
      })), defaultIfEmpty({
        action,
        status: "CANCELED" /* ActionStatus.Canceled */
      }), catchError(error => {
        const ngxsUnhandledErrorHandler = this._ngxsUnhandledErrorHandler ||= this._injector.get(NgxsUnhandledErrorHandler);
        const handleableError = assignUnhandledCallback(error, () => ngxsUnhandledErrorHandler.handleError(error, {
          action
        }));
        return of({
          action,
          status: "ERRORED" /* ActionStatus.Errored */,
          error: handleableError
        });
      }));
    })).subscribe(ctx => this._actionResults.next(ctx));
  }
  /**
   * Invoke actions on the states.
   */
  invokeActions(dispatched$, action) {
    const type = getActionTypeFromInstance(action);
    const results = [];
    // Determines whether the dispatched action has been handled, this is assigned
    // to `true` within the below `for` loop if any `actionMetas` has been found.
    let actionHasBeenHandled = false;
    for (const metadata of this.states) {
      const actionMetas = metadata.actions[type];
      if (actionMetas) {
        for (const actionMeta of actionMetas) {
          const stateContext = this._stateContextFactory.createStateContext(metadata);
          try {
            let result = metadata.instance[actionMeta.fn](stateContext, action);
            // We need to use `isPromise` instead of checking whether
            // `result instanceof Promise`. In zone.js patched environments, `global.Promise`
            // is the `ZoneAwarePromise`. Some APIs, which are likely not patched by zone.js
            // for certain reasons, might not work with `instanceof`. For instance, the dynamic
            // import returns a native promise (not a `ZoneAwarePromise`), causing this check to
            // be falsy.
            if (_isPromise(result)) {
              result = from(result);
            }
            if (isObservable(result)) {
              // If this observable has been completed w/o emitting
              // any value then we wouldn't want to complete the whole chain
              // of actions. Since if any observable completes then
              // action will be canceled.
              // For instance if any action handler would've had such statement:
              // `handler(ctx) { return EMPTY; }`
              // then the action will be canceled.
              // See https://github.com/ngxs/store/issues/1568
              result = result.pipe(mergeMap(value => {
                if (_isPromise(value)) {
                  return from(value);
                }
                if (isObservable(value)) {
                  return value;
                }
                return of(value);
              }), defaultIfEmpty({}));
              if (actionMeta.options.cancelUncompleted) {
                // todo: ofActionDispatched should be used with action class
                result = result.pipe(takeUntil(dispatched$.pipe(ofActionDispatched(action))));
              }
            } else {
              result = of({}).pipe(shareReplay());
            }
            results.push(result);
          } catch (e) {
            results.push(throwError(e));
          }
          actionHasBeenHandled = true;
        }
      }
    }
    // The `NgxsUnhandledActionsLogger` is a tree-shakable class which functions
    // only during development.
    if (NG_DEV_MODE$3 && !actionHasBeenHandled) {
      const unhandledActionsLogger = this._injector.get(NgxsUnhandledActionsLogger, null);
      // The `NgxsUnhandledActionsLogger` will not be resolved by the injector if the
      // `NgxsDevelopmentModule` is not provided. It's enough to check whether the `injector.get`
      // didn't return `null` so we may ensure the module has been imported.
      if (unhandledActionsLogger) {
        unhandledActionsLogger.warn(action);
      }
    }
    if (!results.length) {
      results.push(of({}));
    }
    return forkJoin(results);
  }
  addToStatesMap(stateClasses) {
    const newStates = [];
    const statesMap = this.statesByName;
    for (const stateClass of stateClasses) {
      const stateName = _getStoreMetadata(stateClass).name;
      if (NG_DEV_MODE$3) {
        ensureStateNameIsUnique(stateName, stateClass, statesMap);
      }
      const unmountedState = !statesMap[stateName];
      if (unmountedState) {
        newStates.push(stateClass);
        statesMap[stateName] = stateClass;
      }
    }
    return {
      newStates
    };
  }
  addRuntimeInfoToMeta(meta, path) {
    this.statePaths[meta.name] = path;
    // TODO: versions after v3 - we plan to get rid of the `path` property because it is non-deterministic
    // we can do this when we get rid of the incorrectly exposed getStoreMetadata
    // We will need to come up with an alternative to what was exposed in v3 because this is used by many plugins
    meta.path = path;
  }
  hasBeenMountedAndBootstrapped(name, path) {
    const valueIsBootstrappedInInitialState = getValue(this._initialState, path) !== undefined;
    // This checks whether a state has been already added to the global graph and
    // its lifecycle is in 'bootstrapped' state.
    return this.statesByName[name] && valueIsBootstrappedInInitialState;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function StateFactory_Factory(t) {
      return new (t || StateFactory)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(NgxsConfig), i0.ɵɵinject(StateFactory, 12), i0.ɵɵinject(InternalActions), i0.ɵɵinject(InternalDispatchedActionResults), i0.ɵɵinject(StateContextFactory), i0.ɵɵinject(_INITIAL_STATE_TOKEN, 8));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StateFactory,
      factory: StateFactory.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StateFactory, [{
    type: Injectable
  }], () => [{
    type: i0.Injector
  }, {
    type: NgxsConfig
  }, {
    type: StateFactory,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: InternalActions
  }, {
    type: InternalDispatchedActionResults
  }, {
    type: StateContextFactory
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [_INITIAL_STATE_TOKEN]
    }]
  }], null);
})();
class Store {
  constructor(_stateStream, _internalStateOperations, _config, _internalExecutionStrategy, _stateFactory, initialStateValue) {
    this._stateStream = _stateStream;
    this._internalStateOperations = _internalStateOperations;
    this._config = _config;
    this._internalExecutionStrategy = _internalExecutionStrategy;
    this._stateFactory = _stateFactory;
    /**
     * This is a derived state stream that leaves NGXS execution strategy to emit state changes within the Angular zone,
     * because state is being changed actually within the `<root>` zone, see `InternalDispatcher#dispatchSingle`.
     * All selects would use this stream, and it would call leave only once for any state change across all active selectors.
     */
    this._selectableStateStream = this._stateStream.pipe(leaveNgxs(this._internalExecutionStrategy), shareReplay$1({
      bufferSize: 1,
      refCount: true
    }));
    this.initStateStream(initialStateValue);
  }
  /**
   * Dispatches event(s).
   */
  dispatch(actionOrActions) {
    return this._internalStateOperations.getRootStateOperations().dispatch(actionOrActions);
  }
  /**
   * Selects a slice of data from the store.
   */
  select(selector) {
    const selectorFn = this.getStoreBoundSelectorFn(selector);
    return this._selectableStateStream.pipe(map$1(selectorFn), catchError$1(error => {
      // if error is TypeError we swallow it to prevent usual errors with property access
      if (this._config.selectorOptions.suppressErrors && error instanceof TypeError) {
        return of(undefined);
      }
      // rethrow other errors
      return throwError(error);
    }), distinctUntilChanged(), leaveNgxs(this._internalExecutionStrategy));
  }
  /**
   * Select one slice of data from the store.
   */
  selectOnce(selector) {
    return this.select(selector).pipe(take$1(1));
  }
  /**
   * Select a snapshot from the state.
   */
  selectSnapshot(selector) {
    const selectorFn = this.getStoreBoundSelectorFn(selector);
    return selectorFn(this._stateStream.getValue());
  }
  /**
   * Select a signal from the state.
   */
  selectSignal(selector) {
    const selectorFn = this.getStoreBoundSelectorFn(selector);
    return computed(() => selectorFn(this._stateStream.state()));
  }
  /**
   * Allow the user to subscribe to the root of the state
   */
  subscribe(fn) {
    return this._selectableStateStream.pipe(leaveNgxs(this._internalExecutionStrategy)).subscribe(fn);
  }
  /**
   * Return the raw value of the state.
   */
  snapshot() {
    return this._internalStateOperations.getRootStateOperations().getState();
  }
  /**
   * Reset the state to a specific point in time. This method is useful
   * for plugin's who need to modify the state directly or unit testing.
   */
  reset(state) {
    this._internalStateOperations.getRootStateOperations().setState(state);
  }
  getStoreBoundSelectorFn(selector) {
    const makeSelectorFn = getRootSelectorFactory(selector);
    const runtimeContext = this._stateFactory.getRuntimeSelectorContext();
    return makeSelectorFn(runtimeContext);
  }
  initStateStream(initialStateValue) {
    const value = this._stateStream.value;
    const storeIsEmpty = !value || Object.keys(value).length === 0;
    if (storeIsEmpty) {
      this._stateStream.next(initialStateValue);
    }
  }
  /** @nocollapse */
  static {
    this.ɵfac = function Store_Factory(t) {
      return new (t || Store)(i0.ɵɵinject(i1.ɵStateStream), i0.ɵɵinject(InternalStateOperations), i0.ɵɵinject(NgxsConfig), i0.ɵɵinject(InternalNgxsExecutionStrategy), i0.ɵɵinject(StateFactory), i0.ɵɵinject(_INITIAL_STATE_TOKEN, 8));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Store,
      factory: Store.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Store, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ɵStateStream
  }, {
    type: InternalStateOperations
  }, {
    type: NgxsConfig
  }, {
    type: InternalNgxsExecutionStrategy
  }, {
    type: StateFactory
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [_INITIAL_STATE_TOKEN]
    }]
  }], null);
})();
const NG_DEV_MODE$2 = typeof ngDevMode !== 'undefined' && ngDevMode;
/**
 * InjectionToken that registers preboot functions (called before the root initializer).
 */
const NGXS_PREBOOT_FNS = new InjectionToken(NG_DEV_MODE$2 ? 'NGXS_PREBOOT_FNS' : '');
/**
 * This function registers a preboot function which will be called before the root
 * store initializer is run, but after all of the NGXS features are provided and
 * available for injection. This is useful for registering action stream listeners
 * before any action is dispatched.
 *
 * ```ts
 * bootstrapApplication(AppComponent, {
 *   providers: [
 *     provideStore(
 *       [CountriesState],
 *       withNgxsPreboot(() => {
 *         const actions$ = inject(Actions);
 *         actions$.subscribe(ctx => console.log(ctx));
 *       })
 *     )
 *   ]
 * });
 * ```
 */
function withNgxsPreboot(prebootFn) {
  return makeEnvironmentProviders([{
    provide: NGXS_PREBOOT_FNS,
    multi: true,
    useValue: prebootFn
  }]);
}

/**
 * Allows the select decorator to get access to the DI store, this is used internally
 * in `@Select` decorator.
 */
class SelectFactory {
  static {
    this.store = null;
  }
  static {
    this.config = null;
  }
  constructor(store, config) {
    SelectFactory.store = store;
    SelectFactory.config = config;
  }
  ngOnDestroy() {
    SelectFactory.store = null;
    SelectFactory.config = null;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function SelectFactory_Factory(t) {
      return new (t || SelectFactory)(i0.ɵɵinject(Store), i0.ɵɵinject(NgxsConfig));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SelectFactory,
      factory: SelectFactory.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelectFactory, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Store
  }, {
    type: NgxsConfig
  }], null);
})();
const NG_DEV_MODE$1 = typeof ngDevMode !== 'undefined' && ngDevMode;
class LifecycleStateManager {
  constructor(_store, _internalStateOperations, _stateContextFactory, _appBootstrappedState) {
    this._store = _store;
    this._internalStateOperations = _internalStateOperations;
    this._stateContextFactory = _stateContextFactory;
    this._appBootstrappedState = _appBootstrappedState;
    this._destroy$ = new ReplaySubject(1);
  }
  ngOnDestroy() {
    this._destroy$.next();
  }
  ngxsBootstrap(action, results) {
    if (NG_DEV_MODE$1) {
      if (action instanceof InitState) {
        this._initStateHasBeenDispatched = true;
      } else if (
      // This is a dev mode-only check that ensures the correct order of
      // state initialization. The `NgxsModule.forRoot` or `provideStore` should
      // always come first, followed by `forFeature` and `provideStates`. If the
      // `UpdateState` is dispatched before the `InitState` is dispatched, it indicates
      // that modules or providers are in an invalid order.
      action instanceof UpdateState && !this._initStateHasBeenDispatched) {
        console.error(getInvalidInitializationOrderMessage(action.addedStates));
      }
    }
    this._internalStateOperations.getRootStateOperations().dispatch(action).pipe(filter(() => !!results), tap(() => this._invokeInitOnStates(results.states)), mergeMap(() => this._appBootstrappedState), filter(appBootstrapped => !!appBootstrapped), takeUntil(this._destroy$)).subscribe(() => this._invokeBootstrapOnStates(results.states));
  }
  _invokeInitOnStates(mappedStores) {
    for (const mappedStore of mappedStores) {
      const instance = mappedStore.instance;
      if (instance.ngxsOnChanges) {
        this._store.select(state => getValue(state, mappedStore.path)).pipe(startWith(undefined), pairwise(), takeUntil(this._destroy$)).subscribe(([previousValue, currentValue]) => {
          const change = new NgxsSimpleChange(previousValue, currentValue, !mappedStore.isInitialised);
          instance.ngxsOnChanges(change);
        });
      }
      if (instance.ngxsOnInit) {
        instance.ngxsOnInit(this._getStateContext(mappedStore));
      }
      mappedStore.isInitialised = true;
    }
  }
  _invokeBootstrapOnStates(mappedStores) {
    for (const mappedStore of mappedStores) {
      const instance = mappedStore.instance;
      if (instance.ngxsAfterBootstrap) {
        instance.ngxsAfterBootstrap(this._getStateContext(mappedStore));
      }
    }
  }
  _getStateContext(mappedStore) {
    return this._stateContextFactory.createStateContext(mappedStore);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function LifecycleStateManager_Factory(t) {
      return new (t || LifecycleStateManager)(i0.ɵɵinject(Store), i0.ɵɵinject(InternalStateOperations), i0.ɵɵinject(StateContextFactory), i0.ɵɵinject(i1.ɵNgxsAppBootstrappedState));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LifecycleStateManager,
      factory: LifecycleStateManager.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LifecycleStateManager, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Store
  }, {
    type: InternalStateOperations
  }, {
    type: StateContextFactory
  }, {
    type: i1.ɵNgxsAppBootstrappedState
  }], null);
})();
const NG_DEV_MODE = typeof ngDevMode !== 'undefined' && ngDevMode;
/**
 * This function is shared by both NgModule and standalone features.
 * When using `NgxsModule.forRoot` and `provideStore`, we can depend on the
 * same initialization functionality.
 */
function rootStoreInitializer() {
  const prebootFns = inject(NGXS_PREBOOT_FNS, {
    optional: true
  }) || [];
  prebootFns.forEach(prebootFn => prebootFn());
  const factory = inject(StateFactory);
  const internalStateOperations = inject(InternalStateOperations);
  inject(Store);
  inject(SelectFactory);
  const states = inject(ROOT_STATE_TOKEN, {
    optional: true
  }) || [];
  const lifecycleStateManager = inject(LifecycleStateManager);
  // Add stores to the state graph and return their defaults.
  const results = factory.addAndReturnDefaults(states);
  internalStateOperations.setStateToTheCurrentWithNew(results);
  // Connect our actions stream.
  factory.connectActionHandlers();
  // Dispatch the init action and invoke init and bootstrap functions after.
  lifecycleStateManager.ngxsBootstrap(new InitState(), results);
}
/**
 * This function is utilized by both NgModule and standalone features.
 * When using `NgxsModule.forFeature` and `provideStates`, we can depend on
 * the same initialization functionality.
 */
function featureStatesInitializer() {
  inject(Store);
  const internalStateOperations = inject(InternalStateOperations);
  const factory = inject(StateFactory);
  const states = inject(FEATURE_STATE_TOKEN, {
    optional: true
  }) || [];
  const lifecycleStateManager = inject(LifecycleStateManager);
  // Since FEATURE_STATE_TOKEN is a multi token, we need to
  // flatten it [[Feature1State, Feature2State], [Feature3State]].
  const flattenedStates = states.reduce((total, values) => total.concat(values), []);
  // add stores to the state graph and return their defaults.
  const results = factory.addAndReturnDefaults(flattenedStates);
  if (results.states.length) {
    internalStateOperations.setStateToTheCurrentWithNew(results);
    // Dispatch the update action and invoke init and bootstrap functions after.
    lifecycleStateManager.ngxsBootstrap(new UpdateState(results.defaults), results);
  }
}
/**
 * InjectionToken that registers the global Store.
 */
const NGXS_ROOT_STORE_INITIALIZER = new InjectionToken(NG_DEV_MODE ? 'NGXS_ROOT_STORE_INITIALIZER' : '');
/**
 * InjectionToken that registers feature states.
 */
const NGXS_FEATURE_STORE_INITIALIZER = new InjectionToken(NG_DEV_MODE ? 'NGXS_FEATURE_STORE_INITIALIZER' : '');
const NGXS_ROOT_ENVIRONMENT_INITIALIZER = [{
  provide: NGXS_ROOT_STORE_INITIALIZER,
  useFactory: rootStoreInitializer
}, {
  provide: ENVIRONMENT_INITIALIZER,
  multi: true,
  useFactory() {
    return () => inject(NGXS_ROOT_STORE_INITIALIZER);
  }
}];
/**
 * The `NGXS_FEATURE_ENVIRONMENT_INITIALIZER` functions as an environment initializer
 * at the `Route` level. Angular Router creates an environment route injector for each
 * matched route where navigation occurs. The injector is created once, ensuring that
 * the feature states initialization only happens once as well.
 */
const NGXS_FEATURE_ENVIRONMENT_INITIALIZER = [{
  provide: NGXS_FEATURE_STORE_INITIALIZER,
  useFactory: featureStatesInitializer
}, {
  provide: ENVIRONMENT_INITIALIZER,
  multi: true,
  useFactory() {
    return () => inject(NGXS_FEATURE_STORE_INITIALIZER);
  }
}];

/**
 * @ignore
 */
class NgxsRootModule {
  constructor() {
    rootStoreInitializer();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsRootModule_Factory(t) {
      return new (t || NgxsRootModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxsRootModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsRootModule, [{
    type: NgModule
  }], () => [], null);
})();

/**
 * @ignore
 */
class NgxsFeatureModule {
  constructor() {
    featureStatesInitializer();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsFeatureModule_Factory(t) {
      return new (t || NgxsFeatureModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxsFeatureModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsFeatureModule, [{
    type: NgModule
  }], () => [], null);
})();

/**
 * This function provides the required providers when invoking `NgxsModule.forRoot`
 * or `provideStore`. It is shared between the NgModule and standalone APIs.
 */
function getRootProviders(states, options) {
  return [StateFactory, PluginManager, ...states, {
    provide: ROOT_STATE_TOKEN,
    useValue: states
  }, {
    provide: APP_BOOTSTRAP_LISTENER,
    useFactory: () => {
      const appBootstrappedState = inject(_NgxsAppBootstrappedState);
      return () => appBootstrappedState.bootstrap();
    },
    multi: true
  }, {
    provide: NGXS_OPTIONS,
    useValue: options
  }, {
    provide: CUSTOM_NGXS_EXECUTION_STRATEGY,
    useValue: options.executionStrategy
  }, {
    provide: _NGXS_STATE_CONTEXT_FACTORY,
    useExisting: StateContextFactory
  }, {
    provide: _NGXS_STATE_FACTORY,
    useExisting: StateFactory
  }];
}

/**
 * This function provides the required providers when calling `NgxsModule.forFeature`
 * or `provideStates`. It is shared between the NgModule and standalone APIs.
 */
function getFeatureProviders(states) {
  return [StateFactory, PluginManager, ...states, {
    provide: FEATURE_STATE_TOKEN,
    multi: true,
    useValue: states
  }];
}
class NgxsModule {
  static forRoot(states = [], options = {}) {
    return {
      ngModule: NgxsRootModule,
      providers: getRootProviders(states, options)
    };
  }
  static forFeature(states = []) {
    return {
      ngModule: NgxsFeatureModule,
      providers: getFeatureProviders(states)
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsModule_Factory(t) {
      return new (t || NgxsModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxsModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Decorates a method with action information.
 */
function Action(actions, options) {
  return (target, name,
  // This parameter ensures that the decorated method has a call signature that could be passed an instance of the given action(s).
  _descriptor) => {
    if (typeof ngDevMode !== 'undefined' && ngDevMode) {
      const isStaticMethod = target.hasOwnProperty('prototype');
      if (isStaticMethod) {
        throwActionDecoratorError();
      }
    }
    const meta = _ensureStoreMetadata(target.constructor);
    const actionArray = Array.isArray(actions) ? actions : [actions];
    for (const action of actionArray) {
      const type = action.type;
      if (!meta.actions[type]) {
        meta.actions[type] = [];
      }
      meta.actions[type].push({
        fn: name,
        options: options || {},
        type
      });
    }
  };
}

/**
 * Decorates a class with ngxs state information.
 */
function State(options) {
  return target => {
    const stateClass = target;
    const meta = _ensureStoreMetadata(stateClass);
    const inheritedStateClass = Object.getPrototypeOf(stateClass);
    const optionsWithInheritance = getStateOptions(inheritedStateClass, options);
    mutateMetaData({
      meta,
      inheritedStateClass,
      optionsWithInheritance
    });
    stateClass[_META_OPTIONS_KEY] = optionsWithInheritance;
  };
}
function getStateOptions(inheritedStateClass, options) {
  const inheritanceOptions = inheritedStateClass[_META_OPTIONS_KEY] || {};
  return {
    ...inheritanceOptions,
    ...options
  };
}
function mutateMetaData(params) {
  const {
    meta,
    inheritedStateClass,
    optionsWithInheritance
  } = params;
  const {
    children,
    defaults,
    name
  } = optionsWithInheritance;
  const stateName = typeof name === 'string' ? name : name && name.getName() || null;
  if (typeof ngDevMode !== 'undefined' && ngDevMode) {
    ensureStateNameIsValid(stateName);
  }
  if (inheritedStateClass.hasOwnProperty(_META_KEY)) {
    const inheritedMeta = inheritedStateClass[_META_KEY] || {};
    meta.actions = {
      ...meta.actions,
      ...inheritedMeta.actions
    };
  }
  meta.children = children;
  meta.defaults = defaults;
  meta.name = stateName;
}
const DOLLAR_CHAR_CODE = 36;
function createSelectObservable(selector) {
  if (!SelectFactory.store) {
    throwSelectFactoryNotConnectedError();
  }
  return SelectFactory.store.select(selector);
}
function createSelectorFn(name, rawSelector, paths = []) {
  rawSelector = !rawSelector ? removeDollarAtTheEnd(name) : rawSelector;
  if (typeof rawSelector === 'string') {
    const propsArray = paths.length ? [rawSelector, ...paths] : rawSelector.split('.');
    return propGetter(propsArray, SelectFactory.config);
  }
  return rawSelector;
}
/**
 * @example If `foo$` => make it just `foo`
 */
function removeDollarAtTheEnd(name) {
  const lastCharIndex = name.length - 1;
  const dollarAtTheEnd = name.charCodeAt(lastCharIndex) === DOLLAR_CHAR_CODE;
  return dollarAtTheEnd ? name.slice(0, lastCharIndex) : name;
}

/**
 * Decorator for selecting a slice of state from the store.
 *
 * @deprecated
 * Read the deprecation notice at this link: https://ngxs.io/deprecations/select-decorator-deprecation.
 */
function Select(rawSelector, ...paths) {
  return function (target, key) {
    const name = key.toString();
    const selectorId = `__${name}__selector`;
    const selector = createSelectorFn(name, rawSelector, paths);
    Object.defineProperties(target, {
      [selectorId]: {
        writable: true,
        enumerable: false,
        configurable: true
      },
      [name]: {
        enumerable: true,
        configurable: true,
        get() {
          return this[selectorId] || (this[selectorId] = createSelectObservable(selector));
        }
      }
    });
  };
}
const SELECTOR_OPTIONS_META_KEY = 'NGXS_SELECTOR_OPTIONS_META';
const selectorOptionsMetaAccessor = {
  getOptions: target => {
    return target && target[SELECTOR_OPTIONS_META_KEY] || {};
  },
  defineOptions: (target, options) => {
    if (!target) return;
    target[SELECTOR_OPTIONS_META_KEY] = options;
  }
};
function setupSelectorMetadata(originalFn, creationMetadata) {
  const selectorMetaData = _ensureSelectorMetadata(originalFn);
  selectorMetaData.originalFn = originalFn;
  let getExplicitSelectorOptions = () => ({});
  if (creationMetadata) {
    selectorMetaData.containerClass = creationMetadata.containerClass;
    selectorMetaData.selectorName = creationMetadata.selectorName || null;
    getExplicitSelectorOptions = creationMetadata.getSelectorOptions || getExplicitSelectorOptions;
  }
  const selectorMetaDataClone = {
    ...selectorMetaData
  };
  selectorMetaData.getSelectorOptions = () => getLocalSelectorOptions(selectorMetaDataClone, getExplicitSelectorOptions());
  return selectorMetaData;
}
function getLocalSelectorOptions(selectorMetaData, explicitOptions) {
  return {
    ...(selectorOptionsMetaAccessor.getOptions(selectorMetaData.containerClass) || {}),
    ...(selectorOptionsMetaAccessor.getOptions(selectorMetaData.originalFn) || {}),
    ...(selectorMetaData.getSelectorOptions() || {}),
    ...explicitOptions
  };
}

/**
 * Decorator for setting selector options at a method or class level.
 */
function SelectorOptions(options) {
  return function decorate(target, methodName, descriptor) {
    if (methodName) {
      descriptor ||= Object.getOwnPropertyDescriptor(target, methodName);
      // Method Decorator
      const originalFn = descriptor.value || descriptor.originalFn;
      if (originalFn) {
        selectorOptionsMetaAccessor.defineOptions(originalFn, options);
      }
    } else {
      // Class Decorator
      selectorOptionsMetaAccessor.defineOptions(target, options);
    }
  };
}
function createSelector(selectors, projector, creationMetadata) {
  const memoizedFn = createMemoizedSelectorFn(projector, creationMetadata);
  const selectorMetaData = setupSelectorMetadata(projector, creationMetadata);
  selectorMetaData.makeRootSelector = createRootSelectorFactory(selectorMetaData, selectors, memoizedFn);
  return memoizedFn;
}
function Selector(selectors) {
  return (target, key, descriptor) => {
    descriptor ||= Object.getOwnPropertyDescriptor(target, key);
    const originalFn = descriptor?.value;
    if (typeof ngDevMode !== 'undefined' && ngDevMode) {
      if (originalFn && typeof originalFn !== 'function') {
        throwSelectorDecoratorError();
      }
    }
    const memoizedFn = createSelector(selectors, originalFn, {
      containerClass: target,
      selectorName: key.toString(),
      getSelectorOptions() {
        return {};
      }
    });
    const newDescriptor = {
      configurable: true,
      get() {
        return memoizedFn;
      },
      originalFn
    };
    return newDescriptor;
  };
}
class NgxsDevelopmentModule {
  static forRoot(options) {
    return {
      ngModule: NgxsDevelopmentModule,
      providers: [NgxsUnhandledActionsLogger, {
        provide: NGXS_DEVELOPMENT_OPTIONS,
        useValue: options
      }]
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function NgxsDevelopmentModule_Factory(t) {
      return new (t || NgxsDevelopmentModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxsDevelopmentModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsDevelopmentModule, [{
    type: NgModule
  }], null, null);
})();
function withNgxsDevelopmentOptions(options) {
  return makeEnvironmentProviders([NgxsUnhandledActionsLogger, {
    provide: NGXS_DEVELOPMENT_OPTIONS,
    useValue: options
  }]);
}
function ensureValidSelector(selector, context = {}) {
  const noun = context.noun || 'selector';
  const prefix = context.prefix ? context.prefix + ': ' : '';
  ensureValueProvided(selector, {
    noun,
    prefix: context.prefix
  });
  const metadata = _getSelectorMetadata(selector) || _getStoreMetadata(selector);
  if (!metadata) {
    throw new Error(`${prefix}The value provided as the ${noun} is not a valid selector.`);
  }
}
function ensureValueProvided(value, context = {}) {
  const noun = context.noun || 'value';
  const prefix = context.prefix ? context.prefix + ': ' : '';
  if (!value) {
    throw new Error(`${prefix}A ${noun} must be provided.`);
  }
}
function createModelSelector(selectorMap) {
  const selectorKeys = Object.keys(selectorMap);
  const selectors = Object.values(selectorMap);
  if (typeof ngDevMode !== 'undefined' && ngDevMode) {
    ensureValidSelectorMap({
      prefix: '[createModelSelector]',
      selectorMap,
      selectorKeys,
      selectors
    });
  }
  return createSelector(selectors, (...args) => {
    return selectorKeys.reduce((obj, key, index) => {
      obj[key] = args[index];
      return obj;
    }, {});
  });
}
function ensureValidSelectorMap({
  prefix,
  selectorMap,
  selectorKeys,
  selectors
}) {
  ensureValueProvided(selectorMap, {
    prefix,
    noun: 'selector map'
  });
  ensureValueProvided(typeof selectorMap === 'object', {
    prefix,
    noun: 'valid selector map'
  });
  ensureValueProvided(selectorKeys.length, {
    prefix,
    noun: 'non-empty selector map'
  });
  selectors.forEach((selector, index) => ensureValidSelector(selector, {
    prefix,
    noun: `selector for the '${selectorKeys[index]}' property`
  }));
}
function createPickSelector(selector, keys) {
  if (typeof ngDevMode !== 'undefined' && ngDevMode) {
    ensureValidSelector(selector, {
      prefix: '[createPickSelector]'
    });
  }
  const validKeys = keys.filter(Boolean);
  const selectors = validKeys.map(key => createSelector([selector], s => s[key]));
  return createSelector([...selectors], (...props) => {
    return validKeys.reduce((acc, key, index) => {
      acc[key] = props[index];
      return acc;
    }, {});
  });
}
function createPropertySelectors(parentSelector) {
  if (typeof ngDevMode !== 'undefined' && ngDevMode) {
    ensureValidSelector(parentSelector, {
      prefix: '[createPropertySelectors]',
      noun: 'parent selector'
    });
  }
  const cache = {};
  return new Proxy({}, {
    get(_target, prop) {
      const selector = cache[prop] || createSelector([parentSelector], s => s?.[prop]);
      cache[prop] = selector;
      return selector;
    }
  });
}
function provideStore(states = [], ...optionsAndFeatures) {
  const features = [];
  // Options are empty by default (see `forRoot`).
  let options = {};
  if (optionsAndFeatures.length > 0) {
    if (isEnvironmentProvider(optionsAndFeatures[0])) {
      features.push(...optionsAndFeatures);
    } else {
      options = optionsAndFeatures[0];
      features.push(...optionsAndFeatures.slice(1));
    }
  }
  return makeEnvironmentProviders([...getRootProviders(states, options), NGXS_ROOT_ENVIRONMENT_INITIALIZER, features]);
}
function isEnvironmentProvider(target) {
  return !!target.ɵproviders;
}

/**
 * This version serves as a standalone alternative to `NgxsModule.forFeature`.
 * It can be used in a similar manner to register feature states, but at the
 * `Route` providers level:
 *
 * ```ts
 * const routes: Routes = [
 *   {
 *     path: 'products',
 *     loadComponent: async () => {...},
 *     providers: [provideStates([ProductsState])]
 *   }
 * ];
 * ```
 */
function provideStates(states, ...features) {
  return makeEnvironmentProviders([...getFeatureProviders(states), features, NGXS_FEATURE_ENVIRONMENT_INITIALIZER]);
}

/**
 * This function registers a custom global plugin for the state.
 *
 * ```ts
 * bootstrapApplication(AppComponent, {
 *   providers: [
 *     provideStore(
 *       [CountriesState],
 *       withNgxsPlugin(LogoutPlugin)
 *     )
 *   ]
 * });
 * ```
 */
function withNgxsPlugin(plugin) {
  return makeEnvironmentProviders([{
    provide: NGXS_PLUGINS,
    useClass: plugin,
    multi: true
  }]);
}

/**
 * This function serves as a utility and has multiple purposes.
 * Firstly, it allows you to select properties from the state class
 * without having to inject the store class and use `this.store.selectSignal`,
 * resulting in a more concise implementation. Secondly, it can be used with
 * other solutions such as NgRx signal store with its `signalStoreFeature` or
 * `withComputed` functionalities.
 *
 * Please note that it's named `select` instead of `selectSignal` because
 * signals are evolving into first-class primitives in Angular, displacing other
 * primitives such as observables. Observables represent a stream of events,
 * whereas signals represent a single value changing over time.
 */
function select(selector) {
  return inject(Store).selectSignal(selector);
}
function dispatch(ActionType) {
  const store = inject(Store);
  return (...args) => store.dispatch(new ActionType(...args));
}
function createSelectMap(selectorMap) {
  const store = inject(Store);
  return Object.entries(selectorMap).reduce((accumulator, [key, selector]) => {
    Object.defineProperty(accumulator, key, {
      enumerable: true,
      value: store.selectSignal(selector)
    });
    return accumulator;
  }, {});
}
function createDispatchMap(actionMap) {
  return Object.entries(actionMap).reduce((accumulator, [key, ActionType]) => {
    Object.defineProperty(accumulator, key, {
      enumerable: true,
      value: dispatch(ActionType)
    });
    return accumulator;
  }, {});
}

/**
 * The public api for consumers of @ngxs/store
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Action, Actions, NgxsConfig, NgxsDevelopmentModule, NgxsModule, NgxsSimpleChange, NgxsUnhandledActionsLogger, NgxsUnhandledErrorHandler, NoopNgxsExecutionStrategy, Select, Selector, SelectorOptions, State, Store, createDispatchMap, createModelSelector, createPickSelector, createPropertySelectors, createSelectMap, createSelector, dispatch, ofAction, ofActionCanceled, ofActionCompleted, ofActionDispatched, ofActionErrored, ofActionSuccessful, provideStates, provideStore, select, withNgxsDevelopmentOptions, withNgxsPlugin, withNgxsPreboot, NgxsFeatureModule as ɵNgxsFeatureModule, NgxsRootModule as ɵNgxsRootModule };
